import DateOfBirth from '@/modules/questionnaire/components/steps/common/identity/demographics/DateOfBirth';

export default {
  title: 'Steps/Identity/Demographics/components/DateOfBirth',
  component: DateOfBirth
};

const createStory = (componentProps, initData) => () => ({
  components: { DateOfBirth },
  componentProps,
  wrapStyles: {
    margin: '20px auto',
    width: '360px',
    maxWidth: '100%'
  },
  data() {
    return {
      date: '',
      ...initData
    };
  },
  template:
    '<div :style="$options.wrapStyles"><date-of-birth v-bind="$options.componentProps" v-model="date" /></div>'
});

export const Base = createStory({});

export const WithPrefilledValue = createStory(
  {},
  {
    date: '2015-03-18'
  }
);
